import React, { Component } from 'react'
import axios from 'axios'
import SimpleReactValidator from 'simple-react-validator'
import ReCAPTCHA from "react-google-recaptcha"

const API_PATH = 'https://api.fastweb.dev/contact/sbfireco/index.php';

const recaptchaRef = React.createRef();

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator()
    this.state = {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      message: '',
      loadingState: null,
      mailSent: false,
      mailError: null,
      reply: '',
    }
  }

  handleFormSubmit = e => {
    e.preventDefault();
    this.setState({
      loadingState: true
    })

    const recaptchaValue = recaptchaRef.current.getValue();

    if(!recaptchaValue.length) {
      this.setState({ reply: 'You must not be a robot!' })
      return
    }

    if (this.validator.allValid()) {
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'application/json' },
        data: this.state
      })
      .then(result => {
        this.setState({
          loadingState: false,
          mailSent: result.data.sent
        })
      })
      .catch(error => {
        this.setState({
          loadingState: false,
          mailError: error.message
        })
      })
    } else {
      this.setState({
        loadingState: false,
      })
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <form className='container w-full mx-auto max-w-lg' action='#'>
        <div className="flex flex-wrap mx-3">
          <div className="sm:w-full w-1/2 px-3 sm:px-0 mb-3">
            <label className="font-body block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="fname">
              First Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-indigo-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              aria-label="fname"
              id="fname"
              type="text"
              value={this.state.fname}
              onChange={e => this.setState({ fname: e.target.value })}
              required
            />
            {this.validator.message('first_name', this.state.fname, 'required|alpha_space', {className: 'text-red-500 text-xs italic'})}
          </div>

          <div className="sm:w-full w-1/2 px-3 sm:px-0 mb-3">
            <label
              className="font-body block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="lname"
            >
              Last Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-indigo-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              aria-label="lname"
              id="lname"
              type="text"
              value={this.state.lname}
              onChange={e => this.setState({ lname: e.target.value })}
              required
            />
            {this.validator.message('last_name', this.state.lname, 'required|alpha_space', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        <div className="mx-3 sm:mx-0 mb-6">
          <div className="w-full px-3">
            <label
              className="font-body block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-indigo-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              aria-label="email"
              id="email"
              type="email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              required
            />
            {this.validator.message('email', this.state.email, 'required|email', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        <div className="mx-3 sm:mx-0 mb-6">
          <div className="w-full px-3">
            <label
            className="font-body block tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="phone"
            >
              Phone
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-indigo-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              aria-label="phone"
              id="phone"
              type="tel"
              placeholder='(123) 456-7890 or 123-456-7890'
              value={this.state.phone}
              onChange={e => this.setState({ phone: e.target.value })}
              required
            />
            {this.validator.message('phone', this.state.phone, 'required|phone', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        <div className="mx-3 sm:mx-0 mb-6">
          <div className="w-full px-3">
            <label
              className="font-body block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-indigo-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              aria-label="message"
              placeholder='Please let us know what you need...'
              id="message"
              value={this.state.message}
              onChange={e => this.setState({ message: e.target.value })}
              required
            />
            {this.validator.message('message', this.state.message, 'required|max:1000', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        <div className="mx-3 mb-6">
          <div className='mx-3 mb-6'>
            <ReCAPTCHA
              sitekey="6Ld8yBgaAAAAAEzU0WFEngcoOOmWDXsXgg8q1wEF"
              ref={recaptchaRef}
            />
          </div>

          {this.state.mailSent &&
            <div className='mx-3 mb-6' role='alert'>
              <div className="bg-green-500 text-white font-bold px-4 py-2">
                Thank you!
              </div>
              <div className="border border-t-0 border-green-400 bg-green-100 px-4 py-3 text-green-700">
                <p>Your message has been sent. We will contact you soon.</p>
              </div>
            </div>
          }

          {this.state.mailError &&
            <div className='mx-3 sm:mx-0 mb-6' role='alert'>
              <div className="bg-red-500 text-white font-bold px-4 py-2">
                Oops!
              </div>
              <div className="border border-t-0 border-red-400 bg-red-100 px-4 py-3 text-red-700">
                <p>Sorry, there was a problem sending your message. Please refresh browser and try again. If problem persists, please use other contact channels. Thanks!</p>
              </div>
            </div>
          }

          <button
            className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mx-3 mt-6"
            type='submit'
            onClick={e => this.handleFormSubmit(e)}
          >
            {this.state.loadingState &&
              <svg className="animate-spin h-5 w-5 mr-3 text-white inline-block" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            Send Message
          </button>
        </div>

      </form>
    )
  }
}

export default ContactForm