import React from 'react'

import ContactForm from '../forms/ContactForm'

const PageContactSection = () => (
  <div>
    <div className='flex flex-col w-full bg-red-700 items-center font-body justify-center px-4 py-8 mx-auto'>
      <h1 className='font-display text-4xl text-white text-center'>Contact Us</h1>
    </div>

    <div className='px-24 py-12 sm:px-4 sm:py-12'>
      <ContactForm />
    </div>
  </div>
)

export default PageContactSection